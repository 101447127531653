@charset "UTF-8";
/*提示字体颜色*/
/*弱提示背景色*/
/*圆角大小*/
/*间距8的倍数*/
/**
    flex布局
 */
/**
    flex布局
 */
/**
      文本布局
   */
/**
      flex布局上下左右居中
   */
/**
    文本布局
 */
/**
    渐变背景
 */
/*阴影*/
/* 超出显示省略号... */
.nav-tag[data-v-2b67076a] {
  overflow: hidden;
  height: 0.32rem !important;
}
.nav-tag[data-v-2b67076a] .ant-tabs {
    line-height: 0.16rem;
    height: 100%;
    overflow: hidden;
}
.nav-tag[data-v-2b67076a] .ant-tabs-nav-more {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.85);
}
.nav-tag[data-v-2b67076a] .ant-tabs-nav-container {
    height: 0.32rem !important;
    margin: 0;
    overflow: hidden;
}
.nav-tag[data-v-2b67076a] .ant-tabs-nav {
    height: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
}
.nav-tag[data-v-2b67076a] .ant-tabs-card > .ant-tabs-content {
    height: 0;
    margin-top: -0.16rem;
}
.nav-tag[data-v-2b67076a] .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
    background: #ffffff;
}
.nav-tag[data-v-2b67076a] .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 0;
}
.nav-tag[data-v-2b67076a] .ant-tabs-card > .ant-tabs-bar {
    border-color: #ffffff;
    border: 0;
}
.nav-tag[data-v-2b67076a] .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
    border-color: #ffffff !important;
    background: #ffffff !important;
    line-height: 0.32rem !important;
    height: 0.32rem !important;
    padding: 0 0.1rem;
}
.nav-tag[data-v-2b67076a] .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active .anticon {
    color: #0099FF;
}
.nav-tag[data-v-2b67076a] .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
    border-color: #ffffff !important;
    background: #ffffff !important;
}
